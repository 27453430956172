.best-movie{
    background-image: url('/public/assets/images/woman.jpg');
    background-position: center center;
    background-size: cover;
    width: 100%;
    position: relative;
    height: 70vh;
    min-height: 400px;
    z-index: 1;
    background-color: #000;
    overflow: hidden;
}
.best-movie::after{
    position: absolute;
    content: "";
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: linear-gradient(180deg, #00000085 0%,#141414 100%);
    opacity: 0.5;
}
.best-movie-text-wrapper{
    width: 50%;
    z-index: 2;
    position: relative;
    padding-top: 150px;
    padding-right: 80px;
}
@keyframes fade-right {
    0% {
        transform: translate(-100%, 0%);
        opacity: 0;
    }
    100% {
        transform: translate(0%, 0%);
        opacity: 1;
    }
}
.animation {
    animation: fade-right 2s ease;
}
.best-movie-text-wrapper h1 {
    font-size: 3.4rem;
    font-weight: bold;
    margin-bottom: 20px;
}
.best-movie-text-wrapper p{
    font-size: 18px;
    margin-bottom: 40px;
    line-height: 25px;
}
@media all and (max-width: 1000px) {
    .best-movie-text-wrapper{
        width: 100%;
        padding-top: 50px;
        margin-left: 20px;
        margin-right: 20px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
@media all and (max-width: 550px) {
    .best-movie-text-wrapper{ 
        padding-top: 30px;
    }
    .best-movie-text-wrapper h1 {
        font-size: 2.4rem;
        font-weight: bold;
    }
}