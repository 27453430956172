.news {
    background-color: #141414;
    overflow: hidden;
    Transition: all ease 1s;
}
.news-title{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-content: center;
    align-items: center;
}
.news-title h1 {
    margin: 0;
}
.news-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}
.news-box li {
    width: 25%;
    padding-right: 20px;
}
.news-box li img {
    width: 100%;
    border-radius: 15px;
    margin-bottom: 20px;
}
.news-box li a h3 {
    color: #fff;
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
    margin-bottom: 6px;
}
.news-box li .comments {
    color: #EEB500;
    font-size: 13px;
    margin-top: 0;
    margin-bottom: 13px;
}
.news-box li p {
    color: #B4B4B4;
    margin: 0;
    font-size: 14px;
    line-height: 23px;
}
.news .news-wraper .news-title{
    transform: translateY(250%);
    Transition: all ease 1s;
    opacity: 0;
}
.news .news-wraper .news-box{
    transform: translateY(250%);
    Transition: all ease 1s;
    opacity: 0;
}
.animation-on-both .news-title,
.animation-on-both .news-box,
.animation-on-both{
    transform: translateY(0) !important;
    opacity: 1 !important;
}
@media all and (max-width: 1000px) {
    .news-box li {
        width: 50%;
        padding: 10px;
    }
    .news-title{ 
        padding: 20px;
    }
}
@media all and (max-width: 550px) {
    .news-box li {
        width: 100%;
    }
    .news-title h1{
        font-size: 2.4rem;
    }
    .news .news-wraper .news-title{
        transform: translateY(0);
        opacity: 1;
    }
    .news .news-wraper .news-box{
        transform: translateY(0);
        opacity: 1;
    }
}