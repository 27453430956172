.top-header {
    background-image: url('/public/assets/images/videoprojector.jpg');
    background-position: center center;
    background-size: cover;
    width: 100%;
    position: relative;
    z-index: 1;
    height: 60vh;
    min-height: 400px;
}
.top-header::after{
    position: absolute;
    content: "";
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: linear-gradient(180deg,#000000 0%, #1414143D 100%);
    opacity: 1;
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity:1;
    }
}
.about-us{
    z-index: 2;
    color: #ffffff;
    position: relative;
    text-align: center;
    padding-top: 220px;
    animation: fade-in 3s ease;
}
.about-us h1 {
    font-size: 70px;
    margin-bottom: 20px;
    text-transform: capitalize;
}
.about-us h3 {
    font-size: 1.1rem;
    font-weight: normal;
}
@media all and (max-width: 750px) {
    .about-us{
      padding-top: 180px;
    }
}
@media all and (max-width: 550px) {
    .about-us{
        padding-top: 130px;
    }
    .about-us h1{
        font-size: 45px;
    }
}