.watch {
    background-color: #1D1D1D;
    overflow: hidden;
}
.watch .watch-movie {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 100px 0;
    Transition: all ease-in 0.5s;
}
.watch .watch-movie .macbook{
    display: flex;
    transform: translateX(250%);
    Transition: all ease-in 0.5s;
    opacity: 0;
}

.watch .watch-movie .description{
    display: flex;
    flex-direction: column;
    transform: translateX(-250%);
    Transition: all ease-in 0.5s;
    opacity: 0;
}
.animation-on-both .description,
.animation-on-both .macbook,
.animation-on-both{
    transform: translateX(0) !important;
    opacity: 1 !important;
}
.watch .watch-movie .macbook{
    width: 600px;
}
.watch .watch-movie .macbook img{
    width: 100%;
}

.watch .watch-movie .description{
    width: 500px;
}
.watch .watch-movie .description h1{
    font-size: 3.4rem;
    font-weight: bold;
}
.watch .watch-movie .description p{
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 20px;
    line-height: 25px;
}
.watch .watch-movie img{
    width: 100%;
}
@media all and (max-width: 1100px) {
    .watch .watch-movie .description {
        width: 100%;    
        align-items: center;
        text-align: center;
    }
    .watch .watch-movie .macbook {
        display: none;
    }
}
@media all and (max-width: 550px) {
    .watch .watch-movie .description h1{
        overflow: hidden;
        text-overflow: ellipsis; 
        font-size: 2.4rem;
    }
    .watch .watch-movie .description{
        transform: translateX(0);
        opacity: 1;
    }
}
