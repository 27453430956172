.explore {
    background: #141414;
    text-align: center;
}
.explore .explore-wraper h1 {
    font-size: 3rem;
    margin-bottom: 30px;
}
.explore .explore-wraper .buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    padding: 10px;
}
.explore .explore-wraper .buttons li {
    width: 33.33%;
}
.content-slider {
    position: relative;
    top: 0px;
    left: 0px;
    min-height: 350px;
}
.genre-content {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    margin-bottom: 20px;
    margin-top: 20px;
}
.genre-content.active {
    visibility: visible;
    opacity: 1;
}
@media all and (max-width: 1100px) {
    .content-slider {
        min-height: 550px;
    }
}
@media all and (max-width: 950px) {
    .explore .explore-wraper .buttons li {
    width: 100%;
    padding: 10px;
  }
} 
@media all and (max-width: 600px) {
    .content-slider {
        min-height: 980px;
    }
}
@media all and (max-width: 400px) {
    .content-slider {
        min-height: 2900px;
    }
    .genre-content {
        padding: 20px;
    }
}
@media all and (max-width: 300px) {
    .content-slider {
        min-height: 1900px;
    }
}
