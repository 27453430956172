.full-header {
    background-image: url('/public/assets/images/whatchingtv.jpg');
    background-position: center center;
    background-size: cover;
    width: 100%;
    position: relative;
    z-index: 1;
    height: 90vh;
    min-height: 600px;
    overflow: hidden;
}
.full-header::after{
    position: absolute;
    content: "";
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: linear-gradient(180deg, #00000085 0%,#141414 100%);
    opacity: 1;
}
@keyframes fade-in-up {
    0% {
        transform:translate(-50%, 0%);
        opacity: 0;
    }
    100% {
        opacity:1;
        transform: translate(-50%, -50%);
    }
}
.home-page{
    z-index: 2;
    color: #ffffff;
    text-align: center;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 150px;
    animation: fade-in-up 3s ease;
}
.home-page h1 {
    font-size: 65px;
    margin-bottom: 30px;
    padding-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis; 
}
.home-page h3 {
    font-size: 1.2rem;
    font-weight: normal;
    margin-bottom: 40px;
    padding: 0;
}
.header-actions {
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
@media all and (max-width: 750px) {
    .home-page{
      padding-top: 80px;
    }
}
@media all and (max-width: 550px) {
    .home-page{
        padding-top: 0;
      }
    .home-page h1{
        font-size: 45px;
    }
}