.movie{
    width: 100%;
    height: 90vh;
    min-height: 100px;
    background-position: bottom center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.movie::after{
    position: absolute;
    content: "";
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: linear-gradient(180deg, #00000085 0%,#141414 100%);
    opacity: 1;
}
.movie .movie-player{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 100px 50px 0 50px;
    z-index: 2;
    position: relative;
}
.movie .movie-data{
    margin-top: 100px;
    margin-right: 50px;
    width: 50%;
}
.movie .movie-player .movie-data{
    transform: translateX(-250%);
    Transition: all ease-in 1s;
    opacity: 0;
}
.movie .movie-data .data-box {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
}
.movie .movie-data .data-box p{
 border: 2px solid #fff;
 padding: 5px;
 margin-right: 10px;
 border-radius: 5px;
 font-size: 16px;
}
.movie .movie-data h1{
    font-size: 3rem;
    margin-bottom: 10px;
    margin-top: 20px;
} 
.movie .movie-data p {
    font-size: 17px;
    margin-bottom: 20px;
}
.movie .movie-data .imdb {
 display: flex;
 flex-direction: row;
 align-items: center;
}
.movie .movie-data .imdb p {
    margin-right: 20px;
}
.movie .movie-data .imdb i {
    margin-right: 3px;
    color: #f0a401;
}
.movie .movie-data i {
    margin-right: 20px;
}
.movie .icon{
    position: relative;
    z-index: 2;
    width: 50%;
    transform: translateX(250%);
    Transition: all ease-in 1s;
    opacity: 0;
}
.animation-on-both .movie-data,
.animation-on-both .icon,
.animation-on-both{
    transform: translateX(0) !important;
    opacity: 1 !important;
}
.movie .movie-player .icon .player {
   text-align: center;
}
.movie .movie-player .icon .player i {
    font-size: 60px;
}
.movie .movie-player .icon .player i:hover {
    background-color: #f0a401;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.movie .movie-player .icon .player p{
    background-image: linear-gradient(to left, #ff6c1c, #f0a401);
    background-size: 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 20px;
}
@media all and (max-width: 1000px) {
    .movie .movie-player{
        padding: 50px 10px 0 10px;
    }
}
@media all and (max-width: 800px) {
    .movie .movie-data {
        width: 100%;
    }
}
@media all and (max-width: 600px) {
    .movie .icon {
       display: none;
    }
    .movie .movie-data h1{
        font-size: 2.3rem;
    }
    .movie .movie-player .movie-data{
        transform: translateX(0);
        opacity: 1;
    }
}
@media all and (max-width: 350px) {
    .movie .movie-player{
        padding-top: 10px ;
    }
    .movie .movie-data .data-box {
       display: none;
    }
    .movie .movie-data h1{
        font-size: 2.3rem;
    }
}
