.video-for-everyone{
    background: #141414;
    overflow: hidden;
    Transition: all ease-in 0.5s;
}
.video-for-everyone h1{
    text-align: center;
    margin-bottom: 30px;
    font-size: 3.4rem;
    font-weight: bold;
    font-family: sans-serif;
    text-transform: none;
}
.video-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.video-box li {
    width: 25%;
}
.video-box li .single-video img {
    width: 100%;
    height: auto;
    border-radius: 30px 30px 0px 0px;
}
.single-video {
  margin: 10px;
  background: #1D1D1D;
  border-radius: 10%;
  text-align: center;
}
.single-video a {
    color: #fff;
    font-size: 1.3rem;
}
.video-box li:hover .single-video a {
 color: #EEB500;
}
.single-video-description {
    padding: 35px;
    line-height: 22px;
}
.single-video-description h3 {
    padding-bottom: 10px;
    font-size: 1.8rem;
}

.video-for-everyone .video-for-everyone-wraper .title{
    transform: translateY(250%);
    Transition: all ease-in 0.5s;
    opacity: 0;
}
.video-for-everyone .video-for-everyone-wraper .video-box{
    transform: translateY(250%);
    Transition: all ease-in 0.5s;
    opacity: 0;
}
.animation-on-both .title,
.animation-on-both .video-box,
.animation-on-both{
    transform: translateY(0) !important;
    opacity: 1 !important;
}
@media all and (max-width: 1000px) {
    .video-box li {
        width: 50%;
    }
}
@media all and (max-width: 550px) {
    .video-box li {
        width: 100%;
    }
    .video-for-everyone h1{
        font-size: 2.4rem;
    }
    .video-for-everyone .video-for-everyone-wraper .title {
        transform: translateY(0) !important;
        opacity: 1 !important;
    }
    .video-for-everyone .video-for-everyone-wraper .video-box{
        transform: translateY(0) !important;
        opacity: 1 !important;
    }
}
