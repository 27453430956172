.genre-list-wraper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.genre-list-wraper .genre-style{
    width: 16.66%;
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    padding: 10px;
}
.genre-list .genre-style .genre-image {
    overflow: hidden;
    border-radius: 10px;
}
.genre-list .genre-style .genre-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}
.genre-list .genre-style:hover .genre-image img {
   opacity: 0.2;
}
.genre-style .genre-data {
    position: absolute;
    top: 0;
    left: 0;
    inset: 0;
    min-height: 325px;
    text-align: center;
    padding: 30px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-in-out;
    transform: translateY(500px);
    opacity: 0;
    overflow: hidden;
}
.genre-style:hover .genre-data.fade-up {
    transform: translateX(0);
    opacity: 1;
}
.genre-style:hover .genre-image img.zoom {
    transform: scale(1.5);
    overflow: hidden;
} 
.genre-data i {
    font-size: 35px;
    margin-bottom: 15px;
}
.genre-data h3 {
    font-size: 18px;
}
.genre-data p{
    font-size: 14px;
    margin-bottom: 20px;
}
@media all and (max-width: 1100px) {
    .genre-data i {
        display: none;
    }
    .movie-box .movie-box-hover{
        padding-top: 10px;
    }
}
@media all and (max-width: 1000px) {
    .genre-list .genre-style{
        width: 25%;
    }
}
@media all and (max-width: 800px) {
    .genre-list .genre-style{
        width: 33.33%;
    }
}
@media all and (max-width: 600px) {
    .genre-list .genre-style{
        width: 50%;
    }
}
@media all and (max-width: 400px) {
    .genre-list .genre-style{
        width: 100%;
    }
}