.header{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 5;
    width: 100%;
    padding-bottom: 20px;
    overflow: hidden;
}
.header-style{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    flex-wrap: wrap;
}
.header .menu-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.header .menu-bar .menu {
    position: relative;
    height: 10px;
    width: 60px;
    cursor: pointer;
    text-align: right;
    direction: rtl;
}
.header .menu-bar .menu span{
    position: absolute;
    height: 2px;
    background: #ffffff;
    display: inline-block;
}
.header .menu span:nth-child(1){
    top:0;
    width: 22px;
}
.header .menu span:nth-child(2){
    top: 7px;
    width: 28px;
}
.header .menu span:nth-child(3){
    top: 14px;
    width: 18px;
}
.header .menu-bar img {
    margin-left: 30px;
}
.header.active {
    background: #000;
    transition: all 0.8s ease;
}
@media all and (max-width: 1350px) {
    .header-style{
      padding-right: 30px;
    }
} 
/* @media all and (max-width: 970px) {
    .header-style{
      padding-right: 100px;
    }
}  */
@media all and (max-width: 600px) {
    .menu-bar {
        width: 100%;
    }
    .actions{
      display: none;
    }
} 
