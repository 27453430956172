.footer{
    background: #000;
}
.footer-style {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 100px 30px 0 30px;

}
.footer-style .social img {
    width: 165px;
    height: auto;
}
.footer-style .social .social-media {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 20px;
}
.footer-style .social .social-media a {
    background: #54595F;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: inline-block;
    position: relative;
}
.footer-style .social .social-media i {
    font-size: 16px;
    color: #fff;
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.footer-style .social .social-media a:hover i {
    color: #EEB500;
}
.geners-column ul{
    column-count: 2;
    margin-top: 13px;
    column-gap: 120px
}
.column h3 {
    margin-bottom: 20px;
}
.column a {
    color: #B4B4B4;
}
.column a:hover {
    color: #fff;
}
.footer-style .column h3 {
 font-size: 24px;
 text-transform: capitalize;
}
.column-style a {
    text-transform: capitalize;
}
.footer .footer-bottom {
    padding: 20px 0 30px 0;
    border-top: 1px solid #201F1F;
    margin-top:50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    color: #B4B4B4;
    font-size: 13px;
}
.footer .footer-bottom ul {
    display: flex;
    justify-content: left;
    gap: 28px;
    flex-wrap: wrap;
    width: 50%;
}
.footer .footer-bottom ul li a{
    color: #B4B4B4;
}
@media all and (max-width: 1300px) {
    .footer-style {
        padding: 20px;
    }
}
@media all and (max-width: 1100px) {
    .footer-style .social {
        display: none;
    }
    .footer-bottom {
        text-align: center;
        justify-content: center;
    }
    .footer .footer-bottom ul {
        width: 100%;
        text-align: center;
        justify-content: center;
    }
    .footer .footer-bottom p {
        width: 100%;
    }
}
@media all and (max-width: 600px) {
    .geners-column {
        width: 100%;
    }
}
@media all and (max-width: 350px) {
    .geners-column ul {
     column-count: 1;
     margin-top: 0;
    }
    .support-column {
        width: 100%;
    }
    .about-mozi-column {
        width: 100%;
    }
}